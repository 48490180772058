import React from "react";

import { LevelType } from "../data/MockCollection";
import { IChildViewData } from "../interfaces/IChildViewData";
import { IParentViewData } from "../interfaces/IParentViewData";

interface IProps {
  viewData: IParentViewData[];
  level: LevelType | undefined;
}

export const ViewDataDisplay = (props: IProps) => {
  const itemsList = (items: IChildViewData[]) => {
    return items.map((item, index) => {
      return <li key={index}>{item.name}</li>;
    });
  };
  return (
    <>
      {props.viewData.map((x) => {
        return (
          <>
            <h2>
              {props.level} {x.name}
            </h2>
            <ul>{itemsList(x.children)}</ul>
          </>
        );
      })}
    </>
  );
};
