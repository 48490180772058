import React, { ChangeEvent } from 'react';

import { Button as MuiButton, Typography, useTheme } from '@mui/material';

import { confirmDialog, IConfirmDialogButton } from './ConfirmDialog';

interface IProps {
  text: string;
  variant?: "primary" | "secondary";
  isDisabled?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  handleFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FilePicker = ({
  text,
  variant,
  isDisabled,
  fullWidth,
  style,
  handleFile,
}: IProps) => {
  const theme = useTheme();
  const fileInput = React.useRef<HTMLInputElement>(null);

  // Creating a dynamic click event for fileInput, on the click of browse button element.
  const handleClick = () => {
    /* @ts-ignore*/
    fileInput.current.click();
  };

  const validateFile = (e: any) => {
    const fileName = e.target.files[0].name;
    const fileSplit = fileName.split(".");
    const fileType = fileSplit[fileSplit.length - 1];

    // Allowed file types
    // const fileTypes = ["png", "jpg", "jpeg"];
    const validFileTypes = ["json"];

    if (validFileTypes.indexOf(fileType) === -1) {
      const buttons: IConfirmDialogButton[] = [
        {
          label: "Ok",
          onClick: async () => {},
        },
      ];
      confirmDialog(
        `File type is not supported. Please select a valid image file.`,
        buttons
      );
    } else {
      if (handleFile) {
        handleFile(e);
      }
    }
  };

  return (
    <>
      <input
        ref={fileInput}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => validateFile(e)}
      />

      <MuiButton
        // variant={isDisabled ? undefined : variant}
        type="button"
        style={{ ...style }}
        sx={{
          ":hover": {
            bgcolor: theme.palette.secondary.main, // theme.palette.primary.main
            // color: VlxColors.White,
            border: "none",
          },
          //   backgroundColor: VlxColors.White,
          width: fullWidth ? "100%" : "auto",
          ...style,
        }}
        disabled={isDisabled}
        onClick={handleClick}
      >
        <Typography style={{ ...style }}>{text}</Typography>
      </MuiButton>
    </>
  );
};
