import { UniqueIdentifier } from "@dnd-kit/core";

import { IParentViewData } from "../interfaces/IParentViewData";

export const randomId = (digits?: number) => {
  digits = digits ? Math.pow(10, digits) : 10000;
  return Math.floor(Math.random() * digits);
};

export const randomViewId = () => randomId(4).toString().padStart(4, "0");

export const getParentViewId = (viewId: UniqueIdentifier) =>
  viewId.toString().substring(0, 4);

export const getChildViewId = (viewId: UniqueIdentifier) =>
  viewId.toString().substring(5, 9);

export const viewId = (parentId: UniqueIdentifier, childId: UniqueIdentifier) =>
  `${parentId}-${childId}`;

export const getIndex = (
  id: UniqueIdentifier,
  viewData: IParentViewData[] | undefined
) => {
  const parentContainerViewId = getParentViewId(id);
  if (!parentContainerViewId) {
    return -1;
  }
  const index =
    viewData
      ?.find((x) => x.viewId == parentContainerViewId)
      ?.children.findIndex((x) => x.viewId === id) ?? -1;
  return index;
};

export const getChildIds = (
  containerId: UniqueIdentifier,
  viewData: IParentViewData[] | undefined
) => {
  const children = viewData?.find((x) => x.viewId == containerId)?.children;
  const ids: UniqueIdentifier[] = children
    ? children.map((x) => x.viewId)
    : [""];
  return ids;
};
