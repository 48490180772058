export enum EntityEnum {
  Collection,
  Questionnaire,
  Section,
  Chapter,
  Grouping,
}

export type LevelType =
  | "collection"
  | "questionnaire"
  | "section"
  | "chapter"
  | "grouping";

export interface ICollectionContract {
  collectionId: number;
  display: string;
  sortOrder: number;
  questionnaires: IQuestionnaireContract[];
}

export interface IQuestionnaireContract {
  questionnaireId: number;
  //   collectionId: number;
  display: string;
  sortOrder: number;
  sections: ISectionContract[];
}

export interface ISectionContract {
  sectionId: number;
  //   questionnaireId: number;
  display: string;
  sortOrder: number;
  chapters: IChapterContract[];
}

export interface IChapterContract {
  chapterId: number;
  //   sectionId: number;
  display: string;
  sortOrder: number;
  groupings: IGroupingContract[];
}

export interface IGroupingContract {
  groupingId: number;
  //   chapterId: number;
  display: string;
  sortOrder: number;
}

export const mockCollectionEntityData: ICollectionContract = {
  collectionId: 99,
  display: "99",
  sortOrder: 0,
  questionnaires: [],
};

// export const mockCollectionEntityData: ICollectionContract = {
//   collectionId: 99,
//   display: "99",
//   sortOrder: 0,
//   questionnaires: [
//     {
//       questionnaireId: 1111,
//       display: "1111",
//       sortOrder: 0,
//       sections: [
//         {
//           sectionId: 1001,
//           display: "1001",
//           sortOrder: 0,
//           chapters: [
//             {
//               chapterId: 198,
//               display: "198",
//               sortOrder: 0,
//               groupings: [],
//             },
//             {
//               chapterId: 4423,
//               display: "4423",
//               sortOrder: 0,
//               groupings: [],
//             },
//           ],
//         },
//         {
//           sectionId: 1002,
//           display: "1002",
//           sortOrder: 0,
//           chapters: [],
//         },
//         {
//           sectionId: 1003,
//           display: "1003",
//           sortOrder: 0,
//           chapters: [],
//         },
//         // {
//         //   sectionId: 6710,
//         //   display: "6710",
//         //   sortOrder: 0,
//         //   chapters: [],
//         // },
//         // {
//         //   sectionId: 6365,
//         //   display: "6365",
//         //   sortOrder: 0,
//         //   chapters: [],
//         // },
//       ],
//     },
//     {
//       questionnaireId: 2222,
//       display: "2222",
//       sortOrder: 1,
//       sections: [
//         {
//           sectionId: 2001,
//           display: "2001",
//           sortOrder: 0,
//           chapters: [
//             {
//               chapterId: 6509,
//               display: "6509",
//               sortOrder: 0,
//               groupings: [],
//             },
//             {
//               chapterId: 9754,
//               display: "9754",
//               sortOrder: 0,
//               groupings: [],
//             },
//           ],
//         },
//         {
//           sectionId: 2002,
//           display: "2002",
//           sortOrder: 0,
//           chapters: [],
//         },
//         {
//           sectionId: 2003,
//           display: "2003",
//           sortOrder: 0,
//           chapters: [],
//         },
//         {
//           sectionId: 2004,
//           display: "2004",
//           sortOrder: 0,
//           chapters: [],
//         },
//         // {
//         //   sectionId: 1337,
//         //   display: "1337",
//         //   sortOrder: 0,
//         //   chapters: [],
//         // },
//       ],
//     },
//     {
//       questionnaireId: 3333,
//       display: "3333",
//       sortOrder: 2,
//       sections: [
//         {
//           sectionId: 3001,
//           display: "3001",
//           sortOrder: 0,
//           chapters: [],
//         },
//         // {
//         //   sectionId: 9349,
//         //   display: "9349",
//         //   sortOrder: 0,
//         //   chapters: [],
//         // },
//         // {
//         //   sectionId: 5772,
//         //   display: "5772",
//         //   sortOrder: 0,
//         //   chapters: [],
//         // },
//         // {
//         //   sectionId: 8560,
//         //   display: "8560",
//         //   sortOrder: 0,
//         //   chapters: [],
//         // },
//         // {
//         //   sectionId: 5783,
//         //   display: "5783",
//         //   sortOrder: 0,
//         //   chapters: [],
//         // },
//       ],
//     },
//     // {
//     //   questionnaireId: 4444,
//     //   display: "4444",
//     //   sortOrder: 3,
//     //   sections: [
//     //     {
//     //       sectionId: 1598,
//     //       display: "1598",
//     //       sortOrder: 0,
//     //       chapters: [],
//     //     },
//     //   ],
//     // },
//   ],
// };
