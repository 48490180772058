import { format } from "date-fns";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal, unstable_batchedUpdates } from "react-dom";
import { json } from "stream/consumers";

import {
  CancelDrop,
  closestCenter,
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  getFirstCollision,
  KeyboardCoordinateGetter,
  KeyboardSensor,
  MeasuringStrategy,
  Modifiers,
  MouseSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  SortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Typography } from "@mui/material";

import { DroppableContainer } from "../components/DroppableContainer";
import { FilePicker } from "../components/FilePicker";
import { SortableItem } from "../components/SortableItem";
import { ViewDataDisplay } from "../components/ViewDataDisplay";
import {
  EntityEnum,
  ICollectionContract,
  IQuestionnaireContract,
  LevelType,
  mockCollectionEntityData,
} from "../data/MockCollection";
import { IChildViewData } from "../interfaces/IChildViewData";
import { IMetaViewData } from "../interfaces/IMetaViewData";
import { IParentViewData } from "../interfaces/IParentViewData";
import {
  entityDataToViewData,
  viewDataToEntityData,
  ViewDataType,
} from "../services/DataHelpers";
import {
  getChildIds,
  getIndex,
  getParentViewId,
  randomViewId,
  viewId,
} from "../services/IdHelpers";
import { dropAnimation, getColor, Trash } from "../services/MultiHelpers";
import { coordinateGetter as multipleContainersCoordinateGetter } from "../services/multipleContainersKeyboardCoordinates";
import {
  clearLocalCache,
  LocalStorageEnum,
  writeLocalCache,
} from "../services/SessionStorage";
import {
  applySort,
  childItemIndex,
  getParent,
  idArray,
  parentContainerIndex,
  viewChildren,
} from "../services/ViewDataHelpers";
import { Item } from "../stories/components";
import { Container } from "../stories/components/Container/Container";

export const TRASH_ID = "void";
const PLACEHOLDER_ID = "placeholder";
const empty: UniqueIdentifier[] = [];

interface IProps {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  coordinateGetter?: KeyboardCoordinateGetter;
  handle?: boolean;
  itemCount?: number;
  items?: ViewDataType;
  minimal?: boolean;
  modifiers?: Modifiers;
  removable?: boolean;
  renderItem?: any;
  scrollable?: boolean;
  strategy?: SortingStrategy;
  trashable?: boolean;
  vertical?: boolean;
  wrapperStyle?(args: { index: number }): React.CSSProperties;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
}

export const CollectionPage = (props: IProps) => {
  const {
    adjustScale = false,
    cancelDrop,
    columns,
    containerStyle,
    coordinateGetter = multipleContainersCoordinateGetter,
    getItemStyles = () => ({}),
    handle = false,
    itemCount = 3,
    items: initialItems,
    minimal = false,
    modifiers,
    removable = false,
    renderItem,
    scrollable,
    strategy = verticalListSortingStrategy,
    trashable = false,
    vertical = false,
    wrapperStyle = () => ({}),
  } = props;

  const [viewDataHierarchy, setViewDataHierarchy] =
    useState<IParentViewData[]>();
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [clones, setClones] = useState<IParentViewData[] | null>(null);
  const [metaViewData, setMetaViewData] = useState<IMetaViewData>();
  const [isSortingContainer, setIsSortingContainer] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  //TODO: Level should be managed by a config object
  const parentLevel = queryParams.get("level") as LevelType;
  const parentId = parseInt(queryParams.get("entityId") ?? "0");
  let childLevel: LevelType | undefined = undefined;
  switch (parentLevel) {
    case "collection":
      childLevel = "questionnaire";
      break;
    case "questionnaire":
      childLevel = "section";
      break;
    case "section":
      childLevel = "chapter";
      break;
    case "chapter":
      childLevel = "grouping";
      break;
    case "grouping":
      childLevel = undefined;
      break;
  }

  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  useEffect(() => {
    //TODO: Need logic to run this once at start of entire session, as a safeguard
    // clearLocalCache();
    setMetaViewData({
      entityId: parentId,
      entityLevel: parentLevel,
    });
    const init = async () => {
      let viewData = await entityDataToViewData(parentLevel, parentId);
      viewData = _.orderBy(viewData, (x) => x.sortOrder);
      setViewDataHierarchy(viewData);
      const sortingContainer = activeId
        ? viewData.some((x) => x.entityId.toString() === activeId)
        : false;
      console.log("init data", viewData, sortingContainer);
      setIsSortingContainer(sortingContainer);
    };
    init();
  }, []);

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
    metaViewData &&
      viewDataHierarchy &&
      viewDataToEntityData(metaViewData, viewDataHierarchy);
    const init = async () => {
      // writeLocalCache(LocalStorageEnum.activeCollection, viewDataHierarchy);
      const sortingContainer = activeId
        ? viewDataHierarchy?.some((x) => x.entityId.toString() === activeId)
        : false;
      console.log("updated data", viewDataHierarchy, sortingContainer);
      setIsSortingContainer(sortingContainer || false);
    };
    init();
  }, [metaViewData, viewDataHierarchy]);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const handleSaveFile = () => {
    const json = JSON.stringify(viewDataHierarchy, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = format(new Date(), "yyyy-MM-dd-HH-mm-ss") + ".json"; // new Date().toISOString() + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const handleLoadFile = (
    fileChangeEvent: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (fileChangeEvent.target.files) {
      const file = fileChangeEvent.target.files[0];
      if (file) {
        const fileReader = new FileReader();
        fileReader.readAsText(fileChangeEvent.target.files[0], "UTF-8");
        fileReader.onload = (e) => {
          const contents = e.target?.result?.toString() ?? "";
          const settingsContent: IParentViewData[] = JSON.parse(contents);
          setViewDataHierarchy(settingsContent);
        };
      }
    }
  };

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && viewDataHierarchy && activeId in viewDataHierarchy) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in viewDataHierarchy
          ),
        });
      }
      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");
      if (overId != null) {
        if (overId === TRASH_ID) {
          // If the intersecting droppable is the trash, return early
          // Remove this if you're not using trashable functionality in your app
          return intersections;
        }
        if (viewDataHierarchy && overId in viewDataHierarchy) {
          // const containerItems = viewDataHierarchy[overId];
          const containerItems = viewDataHierarchy.find(
            (x) => x.viewId == overId
          )?.children;
          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          // if (containerItems.length > 0) {
          if ((containerItems?.length ?? 0) > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems?.some((x) => x.viewId === container.id)
              ),
            })[0]?.id;
          }
        }
        lastOverId.current = overId;
        return [{ id: overId }];
      }
      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }
      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, viewDataHierarchy]
  );

  function renderSortableItemDragOverlay(id: UniqueIdentifier) {
    return (
      <Item
        color={getColor(id)}
        dragOverlay
        handle={handle}
        onChange={() => console.log("onChange", "renderContainerDragOverlay")}
        onRemove={() => handleRemoveParent}
        renderItem={renderItem}
        wrapperStyle={wrapperStyle({ index: 0 })}
        style={getItemStyles({
          containerId: getParentViewId(id) as UniqueIdentifier,
          overIndex: -1,
          index: getIndex(id, viewDataHierarchy),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        viewDataItem={
          {
            viewId: "DUMMY",
            name: "DUMMY",
            parentId: "DUMMY",
            entityType: EntityEnum.Section,
            sortOrder: 99,
          } as IChildViewData
        }
      />
    );
  }

  function renderContainerDragOverlay(containerId: UniqueIdentifier) {
    return (
      <Container
        columns={columns}
        label={`${containerId}`}
        onChange={() => console.log("onChange", "renderContainerDragOverlay")}
        shadow
        style={{ height: "100%" }}
        unstyled={false}
      >
        {viewDataHierarchy
          ?.find((x) => x.viewId == containerId)
          ?.children.map((item, index) => (
            <Item
              color={getColor(item.viewId)}
              handle={handle}
              key={item.viewId}
              onRemove={() => handleRemoveParent}
              onChange={() =>
                console.log("onChange", "renderContainerDragOverlay")
              }
              renderItem={renderItem}
              wrapperStyle={wrapperStyle({ index })}
              viewDataItem={
                {
                  viewId: "DUMMY",
                  name: "DUMMY",
                  parentId: "DUMMY",
                  entityType: EntityEnum.Section,
                  sortOrder: 99,
                } as IChildViewData
              }
              style={getItemStyles({
                containerId,
                overIndex: -1,
                index: getIndex(item.viewId, viewDataHierarchy),
                value: item.viewId,
                isDragging: false,
                isSorting: false,
                isDragOverlay: false,
              })}
            />
          ))}
      </Container>
    );
  }

  const handleAddParent = () => {
    const newParentId = randomViewId();
    unstable_batchedUpdates(() => {
      // setParentContainers((containers) => [...containers, newParentId]);
      const newParent: IParentViewData = {
        children: [] as IChildViewData[],
        entityId: parseInt(newParentId),
        entityType: EntityEnum.Questionnaire,
        viewId: newParentId,
        name: newParentId,
        sortOrder: viewDataHierarchy?.length ?? 1000,
      };
      const newHierarchy = viewDataHierarchy
        ? [...viewDataHierarchy, newParent]
        : [newParent];
      console.log("svdh 2");
      setViewDataHierarchy(newHierarchy);
    });
  };

  const handleAddChild = (parentViewId: UniqueIdentifier) => {
    const newChildId = randomViewId();
    const parent = viewDataHierarchy?.find((x) => x.viewId == parentViewId);
    if (parent) {
      const siblings = parent.children ?? ([] as IChildViewData[]);
      const section: IChildViewData = {
        entity: `Entity ${newChildId}`,
        entityId: parseInt(newChildId),
        entityType: EntityEnum.Section,
        viewId: viewId(parentViewId, newChildId),
        name: newChildId,
        parentId: parentViewId.toString(),
        sortOrder: -1,
      };
      siblings.unshift(section);
      parent.children = siblings;
      const updatedViewDataHierarchy = viewDataHierarchy
        ? [...viewDataHierarchy]
        : [];
      const index = updatedViewDataHierarchy.findIndex(
        (x) => x.viewId == parentViewId
      );
      updatedViewDataHierarchy[index] = parent;
      console.log("svdh 3");
      setViewDataHierarchy(updatedViewDataHierarchy);
    }
  };

  const handleRemoveParent = (parentViewId: UniqueIdentifier) => {
    if (removable) {
      const newViewDataHierarchy = viewDataHierarchy?.filter(
        (x) => x.viewId != parentViewId
      );
      setViewDataHierarchy(newViewDataHierarchy);
      // setParentContainers((containers) =>
      //   containers.filter((id) => id !== parentViewId)
      // );
    }
  };

  const handleRemoveChild = (childViewId: UniqueIdentifier) => {
    console.log("handleRemoveChild", childViewId);
    if (removable) {
      const parentViewId = getParentViewId(childViewId);
      const parent = viewDataHierarchy?.find((x) => x.viewId == parentViewId);
      const siblings = parent?.children.filter((x) => x.viewId != childViewId);
      const newHierarchy = viewDataHierarchy ? [...viewDataHierarchy] : [];
      const index = newHierarchy.findIndex((x) => x.viewId == parentViewId);
      index > -1 &&
        (newHierarchy[index].children = siblings
          ? siblings
          : ([] as IChildViewData[]));
      setViewDataHierarchy(newHierarchy);
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
    viewDataHierarchy && setClones(viewDataHierarchy);
  };

  const handleDrageCancel = () => {
    if (clones) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setViewDataHierarchy(clones);
    }
    setActiveId(null);
    setClones(null);
  };

  const handleDragOver = (event: DragOverEvent) => {
    // console.log("handleDragOver", "start");
    // const { active, over } = event;
    // // console.log("--active--", active);
    // // console.log("--over--", over);
    // // console.log("--parentContainers--", parentContainers);
    // const overId = over?.id;
    // if (
    //   overId == null ||
    //   overId === TRASH_ID ||
    //   // What was original purpose of this?  To ignore when dragging container over container?
    //   // active.id in viewDataHierarchy
    //   parentContainers.findIndex((x) => x.toString() === active.id.toString()) >
    //     -1
    // ) {
    //   return;
    // }
    // const overContainerId = getParentViewId(overId);
    // const activeContainerId = getParentViewId(active.id);
    // // console.log("--overContainerId--", overContainerId);
    // // console.log("--activeContainerId--", activeContainerId);
    // if (!overContainerId || !activeContainerId) {
    //   return;
    // }
    // if (activeContainerId !== overContainerId) {
    //   // const activeParentIndex = parentContainers.indexOf(active.id);
    //   // const overParentIndex = parentContainers.indexOf(over?.id ?? -1);
    //   const newViewHierarchy = [...viewDataHierarchy];
    //   const activeChildren = newViewHierarchy.find(
    //     (x) => x.viewId === activeContainerId
    //   )?.children;
    //   const activeChild = activeChildren?.find(
    //     (x) => x.viewId === active.id.toString()
    //   );
    //   const overChildren = newViewHierarchy.find(
    //     (x) => x.viewId === overContainerId
    //   )?.children;
    //   // let newIndex: number;
    //   // const xxx = newViewHierarchy.some(
    //   //   (x) => x.viewId === overId.toString()
    //   // );
    //   // console.log("--xxx--", overId, xxx);
    //   // if (xxx) {
    //   //   newIndex = (overChildren?.length ?? 0) + 1;
    //   //   console.log("a");
    //   // } else {
    //   //   const isBelowOverItem =
    //   //     over &&
    //   //     active.rect.current.translated &&
    //   //     active.rect.current.translated.top >
    //   //       over.rect.top + over.rect.height;
    //   //   const modifier = isBelowOverItem ? 1 : 0;
    //   //   newIndex =
    //   //     overParentIndex >= 0
    //   //       ? overParentIndex + modifier
    //   //       : (overChildren?.length ?? 0) + 1;
    //   //   console.log("b");
    //   // }
    //   // console.log("--newIndex--", newIndex);
    //   recentlyMovedToNewContainer.current = true;
    //   //Update active child's view id
    //   activeChild &&
    //     (activeChild.viewId = viewId(overContainerId, activeChild.entityId));
    //   activeChild && (activeChild.parentId = overContainerId);
    //   //Push active child to target children copy (or other logic to place in specific position)
    //   activeChild && overChildren?.unshift(activeChild);
    //   overChildren?.forEach((x, i) => x.sortOrder == i);
    //   const newActiveChildren = activeChildren?.filter(
    //     (x) => x.viewId !== activeChild?.viewId
    //   );
    //   newActiveChildren?.forEach((x, i) => x.sortOrder == i);
    //   //Replace source and target children
    //   const activeIndex = newViewHierarchy.findIndex(
    //     (x) => x.viewId == activeContainerId
    //   );
    //   const overIndex = newViewHierarchy.findIndex(
    //     (x) => x.viewId == overContainerId
    //   );
    //   newActiveChildren &&
    //     (newViewHierarchy[activeIndex].children = newActiveChildren);
    //   overChildren && (newViewHierarchy[overIndex].children = overChildren);
    //   console.log("--activeChildren--", activeChildren);
    //   console.log("--activeChild--", activeChild);
    //   console.log("--overChildren--", overChildren);
    //   console.log("--newViewHierarchy--", newViewHierarchy);
    //   setViewDataHierarchy(newViewHierarchy);
    // }
    // console.log("handleDragOver", "end");
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    console.log("onDragEnd", "Start");

    if (
      viewDataHierarchy?.some((x) => x.viewId === active.id.toString()) &&
      over?.id
    ) {
      // setParentContainers((containerIds) => {
      //   const activeIndex = containerIds.indexOf(active.id);
      //   const overIndex = containerIds.indexOf(over.id);
      //   const finalContainerIds = arrayMove(
      //     containerIds,
      //     activeIndex,
      //     overIndex
      //   );
      //   return finalContainerIds;
      // });
      console.log("onDragEnd", "a");
    }

    const activeContainerId = getParentViewId(active.id);

    if (!activeContainerId || over?.id == null) {
      console.log("onDragEnd", "Missing required info");
      setActiveId(null);
      return;
    }

    const overContainerId = getParentViewId(over.id);
    const isActiveChild = active.id.toString() !== activeContainerId;
    const isOverChild = over.id.toString() !== overContainerId;
    const isSameParent = activeContainerId === overContainerId;
    const isActiveParent = active.id.toString() === activeContainerId;
    const newViewHierarchy = viewDataHierarchy ? [...viewDataHierarchy] : [];
    const activeParentIndex = parentContainerIndex(
      activeContainerId,
      newViewHierarchy
    );
    const overParentIndex = parentContainerIndex(
      overContainerId,
      newViewHierarchy
    );
    const activeChildren: IChildViewData[] =
      viewChildren(activeContainerId, newViewHierarchy) ?? [];
    const activeChild = activeChildren?.find(
      (x) => x.viewId === active.id.toString()
    );

    //Manage Over Trash
    if (over.id === TRASH_ID) {
      console.log("onDragEnd", "Over Trash");
      // setViewDataHierarchy((items) => ({
      //   ...items,
      //   [activeContainer]: items[activeContainer].filter(
      //     (id) => id.viewId !== activeId
      //   ),
      // }));

      // const xxx = {
      //   ...newViewDataHierarchy,
      //   [activeContainer]: newViewDataHierarchy[activeContainer].filter(
      //     (id) => id.viewId !== activeId
      //   ),
      // };
      setViewDataHierarchy(newViewHierarchy);
      setActiveId(null);
      return;
    }

    //Manage Over Placeholder
    if (over.id === PLACEHOLDER_ID) {
      console.log("onDragEnd", "Over Placeholder");
      const newContainerId = randomViewId(); // getNextContainerId();
      unstable_batchedUpdates(() => {
        // setParentContainers((containerIds) => [
        //   ...containerIds,
        //   newContainerId,
        // ]);
        // setViewDataHierarchy((items) => ({
        //   ...items,
        //   [activeContainer]: items[activeContainer].filter(
        //     (id) => id.viewId !== activeId
        //   ),
        //   [newContainerId]: [{ viewId: active.id } as IChildViewData],
        // }));
        // const xxx = {
        //   ...items,
        //   [activeContainer]: items[activeContainer].filter(
        //     (id) => id.viewId !== activeId
        //   ),
        //   [newContainerId]: [{ viewId: active.id } as IChildViewData],
        // };
        setViewDataHierarchy(newViewHierarchy);

        setActiveId(null);
      });
      return;
    }

    //Managing an Active Parent Container
    if (isActiveParent) {
      console.log("--Managing an Active Parent Container--");
      if (overContainerId) {
        const activeItem = viewDataHierarchy?.find(
          (x) => x.viewId === activeContainerId
        );
        const overItem = viewDataHierarchy?.find(
          (x) => x.viewId === overContainerId
        );
        //SortOrder belongs to the view-data and is the relative position. Index belongs to the display object and is the absolute position. Can't assume data sort number will start with 0 or increase sequentially by 1.
        const activeIndex =
          (activeItem ? viewDataHierarchy?.indexOf(activeItem) : -1) || -1;
        const overIndex =
          (overItem ? viewDataHierarchy?.indexOf(overItem) : -1) || -1;

        console.log("onDragEnd", "f");

        if (activeIndex !== overIndex) {
          const isAhead = activeIndex > overIndex;
          //Get the first part of the array
          const sliceLength = isAhead ? overIndex : overIndex - 1;
          const prepend = viewDataHierarchy?.slice(0, sliceLength);
          let newDataHierarchy = prepend ? [...prepend] : [];
          //Place the dragged item in new position
          if (isAhead) {
            activeItem && newDataHierarchy.push(activeItem);
          } else {
            overItem && newDataHierarchy.push(overItem);
          }
          //Get second part of array, removing item from its original position
          const processingId = isAhead ? activeContainerId : overContainerId;
          const append = viewDataHierarchy
            ?.slice(sliceLength)
            .filter((x) => x.viewId != processingId);
          append && (newDataHierarchy = newDataHierarchy.concat(append));
          //Update the new hierarchy items' sort orders
          // newDataHierarchy.forEach((item, index) => {
          //   item.sortOrder = index;
          // });
          console.log("newDataHierarchy", newDataHierarchy);
          applySort(newDataHierarchy);
          console.log("newDataHierarchy", newDataHierarchy);
          console.log("onDragEnd", "g");
          setViewDataHierarchy(newDataHierarchy);
          // const finalContainerIds = arrayMove(
          //   parentContainers,
          //   activeIndex,
          //   overIndex
          // );
          // setParentContainers(finalContainerIds);
        }
      }
    }

    //Managing an Active Child
    if (isActiveChild) {
      console.log("--Managing an Active Child--");

      //Child Active, Container Over
      if (isActiveChild && !isOverChild) {
        //Missing required info
        if (!overContainerId || !activeContainerId) {
          return;
        }

        //Ensure over object and active are legitimate
        if (
          over.id == null ||
          over.id === TRASH_ID
          // ||
          // viewDataHierarchy.findIndex(
          //   (x) => x.toString() === active.id.toString()
          // ) > -1
        ) {
          return;
        }

        //Manage Active Child Over New Container
        if (activeContainerId !== overContainerId) {
          console.log("--Child Active, Over New Container--");
          const overChildren = viewChildren(overContainerId, newViewHierarchy);
          recentlyMovedToNewContainer.current = true;
          if (activeChild) {
            //Update active child's ids
            activeChild.viewId = viewId(overContainerId, activeChild.entityId);
            activeChild.parentId = overContainerId;
            //Push active child to target children copy (or other logic to place in specific position)
            overChildren?.unshift(activeChild);
          }
          overChildren?.forEach((x, i) => (x.sortOrder = i));
          const newActiveChildren = activeChildren?.filter(
            (x) => x.viewId !== activeChild?.viewId
          );
          newActiveChildren?.forEach((x, i) => (x.sortOrder = i));
          //Replace source and target children
          newActiveChildren &&
            (newViewHierarchy[activeParentIndex].children = newActiveChildren);
          overChildren &&
            (newViewHierarchy[overParentIndex].children = overChildren);
          setViewDataHierarchy(newViewHierarchy);
        }
      }

      if (isActiveChild && isOverChild) {
        const activeParentContainer = viewDataHierarchy?.find(
          (x) => x.viewId === activeContainerId
        );
        const overParentContainer = viewDataHierarchy?.find(
          (x) => x.viewId === overContainerId
        );
        const source = isSameParent
          ? activeParentContainer
          : overParentContainer;
        const overChild = source?.children.find(
          (x) => x.viewId === over.id.toString()
        );
        const overChildren: IChildViewData[] =
          overParentContainer?.children ?? [];

        if (isSameParent) {
          //Manage Active and Over Children, Over is Same Container
          console.log("--Both Children, Same Container--");
          const activeChildIndex = activeChildren.indexOf(activeChild!);
          const overChildIndex = activeChildren.indexOf(overChild!);
          const isAhead = activeChildIndex > overChildIndex;
          //Get the first part of the array
          const sliceLength = isAhead ? overChildIndex : overChildIndex - 1;
          const prepend = activeChildren.slice(0, sliceLength);
          let newChildHierarchy = [...prepend];
          //Place the dragged item in new position
          if (isAhead) {
            activeChild && newChildHierarchy.push(activeChild);
          } else {
            overChild && newChildHierarchy.push(overChild);
          }
          //Get second part of array, removing item from its original position
          const processingId = isAhead ? active.id : over.id;
          const append = activeChildren
            .slice(sliceLength)
            .filter((x) => x.viewId != processingId);
          newChildHierarchy = newChildHierarchy.concat(append);
          //Update the new hierarchy items' sort orders
          applySort(newChildHierarchy);
          newViewHierarchy[activeParentIndex].children = newChildHierarchy;
          setViewDataHierarchy(newViewHierarchy);
        } else {
          //Manage Active and Over Children, Over is New Container
          console.log("--Both Children, New Container--");
          const newActiveChildHierarchy = activeChildren.filter(
            (x) => x.viewId !== activeChild?.viewId
          );
          applySort(newActiveChildHierarchy);
          newViewHierarchy[activeParentIndex].children =
            newActiveChildHierarchy;
          const overChildIndex = overChildren.indexOf(overChild!);
          let newOverChildHierarchy = overChildren.slice(0, overChildIndex);
          activeChild!.viewId = viewId(overContainerId, activeChild!.entityId);
          activeChild!.parentId = overContainerId;
          newOverChildHierarchy.push(activeChild!);
          const append = overChildren
            .slice(overChildIndex)
            .filter((x) => x.viewId != activeChild?.viewId);
          newOverChildHierarchy = newOverChildHierarchy.concat(append);
          applySort(newOverChildHierarchy);
          newViewHierarchy[overParentIndex].children = newOverChildHierarchy;
          setViewDataHierarchy(newViewHierarchy);
        }
      }
    }
    setActiveId(null);
    console.log("onDragEnd", "End");
  };

  const handleSortableItemChange = (childItem: IChildViewData) => {
    const newViewHierarchy = viewDataHierarchy ? [...viewDataHierarchy] : [];
    const parentIndex = parentContainerIndex(
      childItem.parentId,
      newViewHierarchy
    );
    const childIndex = childItemIndex(childItem, newViewHierarchy);
    newViewHierarchy[parentIndex].children[childIndex] = childItem;
    setViewDataHierarchy(newViewHierarchy);
    console.log("handleSortableItemChange", childIndex);
  };

  const handleParentItemChange = (containerId: string, name: string) => {
    const newViewHierarchy = viewDataHierarchy ? [...viewDataHierarchy] : [];
    const parentIndex = parentContainerIndex(containerId, newViewHierarchy);
    newViewHierarchy[parentIndex].name = name;
    setViewDataHierarchy(newViewHierarchy);
  };

  return (
    <>
      {/* <Typography>{parentLevel} Page</Typography> */}
      {viewDataHierarchy && (
        <ViewDataDisplay viewData={viewDataHierarchy} level={childLevel} />
      )}
      <div>
        <FilePicker
          text="Upload a Settings Files"
          variant="primary"
          handleFile={(e) => handleLoadFile(e)}
          style={{ width: "40%", padding: 0 }}
        />
        <button onClick={() => handleSaveFile()}>Save</button>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        onDragStart={(event) => handleDragStart(event)}
        onDragOver={(event) => handleDragOver(event)}
        onDragEnd={(event) => handleDragEnd(event)}
        cancelDrop={cancelDrop}
        onDragCancel={handleDrageCancel}
        modifiers={modifiers}
      >
        <div
          style={{
            display: "inline-grid",
            boxSizing: "border-box",
            padding: 20,
            gridAutoFlow: vertical ? "row" : "column",
          }}
        >
          <SortableContext
            items={[...idArray(viewDataHierarchy), PLACEHOLDER_ID]}
            strategy={
              vertical
                ? verticalListSortingStrategy
                : horizontalListSortingStrategy
            }
          >
            {idArray(viewDataHierarchy).map((containerId) => (
              <DroppableContainer
                key={containerId}
                id={containerId}
                label={minimal ? undefined : `${containerId}`}
                columns={columns}
                items={getChildIds(containerId, viewDataHierarchy)}
                scrollable={scrollable}
                style={containerStyle}
                unstyled={minimal}
                onRemove={() => handleRemoveParent(containerId)}
                onAdd={() => handleAddChild(containerId)}
                onChange={(value: string) => {
                  handleParentItemChange(containerId.toString(), value);
                }}
              >
                <div
                  style={{
                    height: "50vh",
                    // margin: "200px auto 0",
                    overflow: "auto",
                  }}
                >
                  <SortableContext
                    items={getChildIds(containerId, viewDataHierarchy)}
                    strategy={strategy}
                  >
                    {viewDataHierarchy
                      ?.find((x) => x.viewId == containerId)
                      ?.children.map((value, index) => {
                        return (
                          <SortableItem
                            containerId={containerId}
                            disabled={isSortingContainer}
                            getIndex={(id) => getIndex(id, viewDataHierarchy)}
                            handle={handle}
                            id={value.viewId}
                            index={index}
                            key={value.viewId}
                            onChange={(viewItem) =>
                              handleSortableItemChange(viewItem)
                            }
                            onRemove={() => handleRemoveChild(value.viewId)}
                            renderItem={renderItem}
                            style={getItemStyles}
                            viewItemData={value}
                            wrapperStyle={wrapperStyle}
                          />
                        );
                      })}
                  </SortableContext>
                </div>
              </DroppableContainer>
            ))}
            {minimal ? undefined : (
              <DroppableContainer
                disabled={isSortingContainer}
                id={PLACEHOLDER_ID}
                items={empty}
                onChange={() => console.log("onChange", "minimal")}
                onClick={handleAddParent}
                placeholder
              >
                + Add Questionnaire
              </DroppableContainer>
            )}
          </SortableContext>
        </div>
        {createPortal(
          <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
            {activeId
              ? idArray(viewDataHierarchy).includes(activeId.toString())
                ? renderContainerDragOverlay(activeId)
                : renderSortableItemDragOverlay(activeId)
              : null}
          </DragOverlay>,
          document.body
        )}
        {trashable &&
        activeId &&
        !idArray(viewDataHierarchy).includes(activeId.toString()) ? (
          <Trash id={TRASH_ID} />
        ) : null}
      </DndContext>
    </>
  );
};
