import React from "react";

import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";

import { IChildViewData } from "../interfaces/IChildViewData";
import { getColor, useMountStatus } from "../services/MultiHelpers";
import { Item } from "../stories/components";

interface IProps {
  containerId: UniqueIdentifier;
  disabled?: boolean;
  getIndex(id: UniqueIdentifier): number;
  handle: boolean;
  id: UniqueIdentifier;
  index: number;
  onChange: (viewItem: IChildViewData) => void;
  onRemove?: (containerID: UniqueIdentifier) => void | undefined;
  renderItem(): React.ReactElement;
  style(args: any): React.CSSProperties;
  viewItemData: IChildViewData;
  wrapperStyle({ index }: { index: number }): React.CSSProperties;
}

export function SortableItem(props: IProps) {
  const {
    containerId,
    disabled,
    getIndex,
    handle,
    id,
    index,
    onChange,
    onRemove,
    renderItem,
    style,
    viewItemData,
    wrapperStyle,
  } = props;

  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({ id });

  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  const handleOnRemove = () => {
    onRemove && onRemove("");
  };

  return (
    <Item
      color={getColor(id)}
      dragging={isDragging}
      fadeIn={mountedWhileDragging}
      handle={handle}
      handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
      index={index}
      listeners={listeners}
      onChange={onChange}
      onRemove={handleOnRemove}
      ref={disabled ? undefined : setNodeRef}
      renderItem={renderItem}
      sorting={isSorting}
      transform={transform}
      transition={transition}
      viewDataItem={viewItemData}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
    />
  );
}
