import { CollectionPage } from "./pages/CollectionPage";

function App() {
  return (
    <CollectionPage
      containerStyle={
        {
          // maxHeight: "80vh",
        }
      }
      itemCount={5}
      scrollable
      handle
      removable
    />
  );
}

export default App;
