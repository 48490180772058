import './confirm-dialog.css';

import PropTypes from 'prop-types';
import { Component } from 'react';
import { createRoot } from 'react-dom/client';

//TODO: NEED TO PROPERLY BREAK THIS OUT INTO A SERVICE AND HELPERS.  THE SIG IS A METHOD, NOT A COMPONENT.

export const STANDARD_SUBMIT_ERROR =
  "There was an error with your submission. If you continue experiencing issues, please contact VivaLynx.";

export interface IConfirmDialogButton {
  label: string;
  order?: number;
  onClick: () => void;
}

export const confirmDialog = (
  message: string,
  buttons?: IConfirmDialogButton[]
) => {
  const defaultButtons = [
    {
      label: "Ok",
      onClick: () => {},
    },
  ];
  confirmAlert({
    title: "VivaLynx",
    message: message,
    buttons: buttons ?? defaultButtons,
  });
  return;
};

export const vlxConfirmation = async (
  message: string,
  actionLabel: string,
  actionMethod: () => Promise<void>
) => {
  const buttons: IConfirmDialogButton[] = [
    {
      label: actionLabel,
      onClick: async () => {
        await actionMethod();
      },
    },
    {
      label: "Cancel",
      onClick: () => {},
    },
  ];
  confirmDialog(message, buttons);
};

export const vlxSuccess = async (
  message: string,
  actionMethod: () => Promise<void>
) => {
  const okButton: IConfirmDialogButton[] = [
    {
      label: "Ok",
      onClick: async () => {
        await actionMethod();
      },
    },
  ];
  confirmDialog(message, okButton);
};

//Forked from react-confirm-alert
class VlxAlert extends Component {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    buttons: PropTypes.array.isRequired,
    childrenElement: PropTypes.func,
    customUI: PropTypes.func,
    closeOnClickOutside: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    keyCodeForClose: PropTypes.arrayOf(PropTypes.number),
    willUnmount: PropTypes.func,
    afterClose: PropTypes.func,
    onClickOutside: PropTypes.func,
    onKeypressEscape: PropTypes.func,
    onkeyPress: PropTypes.func,
    overlayClassName: PropTypes.string,
  };

  static defaultProps = {
    buttons: [
      {
        label: "Cancel",
        onClick: () => null,
        className: null,
      },
      {
        label: "Confirm",
        onClick: () => null,
        className: null,
      },
    ],
    childrenElement: () => null,
    closeOnClickOutside: true,
    closeOnEscape: true,
    keyCodeForClose: [],
    willUnmount: () => null,
    afterClose: () => null,
    onClickOutside: () => null,
    onKeypressEscape: () => null,
  };

  handleClickButton = (button: any) => {
    if (button.onClick) button.onClick();
    this.close();
  };

  handleClickOverlay = (e: any) => {
    const { closeOnClickOutside, onClickOutside } = this.props as any;
    const isClickOutside = e.target === (this as any).overlay;

    if (closeOnClickOutside && isClickOutside) {
      onClickOutside();
      this.close();
    }

    e.stopPropagation();
  };

  close = () => {
    const { afterClose } = this.props as any;
    removeBodyClass();
    removeElementReconfirm(this.props);
    removeSVGBlurReconfirm(afterClose);
  };

  keyboard = (event: any) => {
    const { closeOnEscape, onKeypressEscape, onkeyPress, keyCodeForClose } =
      this.props as any;
    const keyCode = event.keyCode;
    const isKeyCodeEscape = keyCode === 27;

    if (keyCodeForClose.includes(keyCode)) {
      this.close();
    }

    if (closeOnEscape && isKeyCodeEscape) {
      onKeypressEscape(event);
      this.close();
    }

    if (onkeyPress) {
      onkeyPress();
    }
  };

  componentDidMount = () => {
    document.addEventListener("keydown", this.keyboard, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.keyboard, false);
    (this.props as any).willUnmount();
  };

  renderCustomUI = () => {
    const { title, message, buttons, customUI } = this.props as any;
    const dataCustomUI = {
      title,
      message,
      buttons,
      onClose: this.close,
    };

    return customUI(dataCustomUI);
  };

  render() {
    const {
      title,
      message,
      buttons,
      childrenElement,
      customUI,
      overlayClassName,
    } = this.props as any;

    return (
      <div
        className={`vlx-alert-overlay ${overlayClassName}`}
        ref={(dom) => ((this as any).overlay = dom)}
        onClick={this.handleClickOverlay}
      >
        <div className="vlx-alert">
          {customUI ? (
            this.renderCustomUI()
          ) : (
            <div className="vlx-alert-body">
              {title && <h1>{title}</h1>}
              {message}
              {childrenElement()}
              <div className="vlx-alert-button-group">
                {buttons.map((button: any, i: number) => (
                  <button
                    key={i}
                    className={button.className}
                    {...button}
                    onClick={(e) => this.handleClickButton(button)}
                  >
                    {button.label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

let root: any = null;
const targetId = "vlx-alert";

function createSVGBlurReconfirm() {
  // If has svg ignore to create the svg
  const svg = document.getElementById("vlx-alert-firm-svg");
  if (svg) return;
  const svgNS = "http://www.w3.org/2000/svg";
  const feGaussianBlur = document.createElementNS(svgNS, "feGaussianBlur");
  feGaussianBlur.setAttribute("stdDeviation", "0.3");

  const filter = document.createElementNS(svgNS, "filter");
  filter.setAttribute("id", "gaussian-blur");
  filter.appendChild(feGaussianBlur);

  const svgElem = document.createElementNS(svgNS, "svg");
  svgElem.setAttribute("id", "vlx-alert-firm-svg");
  svgElem.setAttribute("class", "vlx-alert-svg");
  svgElem.appendChild(filter);

  document.body.appendChild(svgElem);
}

function removeSVGBlurReconfirm(afterClose: any) {
  const svg = document.getElementById("vlx-alert-firm-svg");
  if (svg && svg.parentNode) {
    svg.parentNode.removeChild(svg);
  }
  document.body.children[0].classList.remove("vlx-alert-blur");
  afterClose();
}

function createElementReconfirm(properties: any) {
  let divTarget = document.getElementById(properties.targetId || targetId);

  if (properties.targetId && !divTarget) {
    console.error(
      "React Confirm Alert:",
      `Can not get element id (#${properties.targetId})`
    );
  }

  if (divTarget) {
    root = createRoot(divTarget);
    root.render(<VlxAlert {...properties} />);
  } else {
    document.body.children[0].classList.add("vlx-alert-blur");
    divTarget = document.createElement("div");
    divTarget.id = targetId;
    document.body.appendChild(divTarget);
    root = createRoot(divTarget);
    root.render(<VlxAlert {...properties} />);
  }
}

function removeElementReconfirm(properties: any) {
  const target = document.getElementById(properties.targetId || targetId);
  if (target) {
    root.unmount(target);
  }
}

function addBodyClass() {
  document.body.classList.add("vlx-alert-body-element");
}

function removeBodyClass() {
  document.body.classList.remove("vlx-alert-body-element");
}

const confirmAlert = (properties: any) => {
  addBodyClass();
  createSVGBlurReconfirm();
  createElementReconfirm(properties);
};
