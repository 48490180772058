import classNames from "classnames";
import React, { useEffect } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import { IChildViewData } from "../../../interfaces/IChildViewData";
import { Handle, Remove } from "./components";
import styles from "./Item.module.css";

import type { DraggableSyntheticListeners } from "@dnd-kit/core";
import type { Transform } from "@dnd-kit/utilities";
interface IProps {
  color?: string;
  disabled?: boolean;
  dragOverlay?: boolean;
  dragging?: boolean;
  fadeIn?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  listeners?: DraggableSyntheticListeners;
  onRemove?(): void;
  onChange: (viewItem: IChildViewData) => void;
  sorting?: boolean;
  style?: React.CSSProperties;
  transform?: Transform | null;
  transition?: string | null;
  viewDataItem: IChildViewData;
  wrapperStyle?: React.CSSProperties;
  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    fadeIn: boolean;
    index: number | undefined;
    label: IProps["viewDataItem"]["name"];
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    sorting: boolean;
    style: React.CSSProperties | undefined;
    transform: IProps["transform"];
    transition: IProps["transition"];
  }): React.ReactElement;
}

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, IProps>(
    (
      {
        color,
        disabled,
        dragOverlay,
        dragging,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onChange,
        onRemove,
        renderItem,
        sorting,
        style,
        transform,
        transition,
        viewDataItem,
        wrapperStyle,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }
        document.body.style.cursor = "grabbing";
        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      const handleNameChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => {
        const item: IChildViewData = {
          ...viewDataItem,
          name: event.target.value,
        };
        console.log("handleNameChange", item);
        onChange(item);
      };

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          label: viewDataItem.name,
        })
      ) : (
        <li
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay
          )}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(", "),
              "--translate-x": transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              "--translate-y": transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              "--scale-x": transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              "--scale-y": transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              "--index": index,
              "--color": color,
            } as React.CSSProperties
          }
          ref={ref}
        >
          <div
            className={classNames(
              styles.Item,
              dragging && styles.dragging,
              handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color
            )}
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            {/* {`Section ${value}`} */}

            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography> {`Section: `}</Typography>
                  <TextField
                    defaultValue={viewDataItem.name}
                    onChange={handleNameChange}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{viewDataItem.viewId}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <span className={styles.Actions}>
              {onRemove ? (
                // <Remove className={styles.Remove} onClick={onRemove} />
                <Remove onClick={onRemove} />
              ) : null}
              {handle ? <Handle {...handleProps} {...listeners} /> : null}
            </span>
          </div>
        </li>
      );
    }
  )
);
