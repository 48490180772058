import { IChildViewData } from "../interfaces/IChildViewData";
import { IParentViewData } from "../interfaces/IParentViewData";

export const viewChildren = (
  parentContainerId: string,
  viewHierarchy: IParentViewData[]
) => viewHierarchy.find((x) => x.viewId === parentContainerId)?.children;

export const parentContainerIndex = (
  parentContainerId: string,
  viewHierarchy: IParentViewData[]
) => viewHierarchy.findIndex((x) => x.viewId == parentContainerId);

export const childItemIndex = (
  child: IChildViewData,
  viewHierarchy: IParentViewData[]
) => {
  const parentIndex = parentContainerIndex(child.parentId, viewHierarchy);
  const childIndex = viewHierarchy[parentIndex].children.findIndex(
    (x) => x.viewId === child.viewId
  );
  return childIndex;
};

export const getParent = (parentId: string, viewHierarchy: IParentViewData[]) =>
  viewHierarchy.find((x) => x.viewId === parentId);

export const getSiblings = (
  child: IChildViewData,
  viewHierarchy: IParentViewData[]
) =>
  viewChildren(child.parentId, viewHierarchy)?.filter(
    (x) => x.viewId !== child.viewId
  );

export const applySort = (hierarchy: IParentViewData[] | IChildViewData[]) =>
  hierarchy.forEach((item, index) => {
    item.sortOrder = index;
  });

export const idArray = (
  hierarchy: IParentViewData[] | IChildViewData[] | undefined
) => hierarchy?.map((x) => x.viewId) ?? [];
