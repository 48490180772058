import _ from "lodash";

import { UniqueIdentifier } from "@dnd-kit/core";

import {
  EntityEnum,
  IChapterContract,
  ICollectionContract,
  IQuestionnaireContract,
  ISectionContract,
  LevelType,
  mockCollectionEntityData,
} from "../data/MockCollection";
import { IChildViewData } from "../interfaces/IChildViewData";
import { IMetaViewData } from "../interfaces/IMetaViewData";
import { IParentViewData } from "../interfaces/IParentViewData";
import { randomViewId, viewId } from "./IdHelpers";
import { fetchLocalCache, LocalStorageEnum } from "./SessionStorage";

export type ViewDataType = Record<UniqueIdentifier, IChildViewData[]>;

const defaultInitializer = (index: number) => index;

export function createRange<T = number>(
  length: number,
  initializer: (index: number) => any = defaultInitializer
): T[] {
  const result = [...new Array(length)].map((_, index) => initializer(index));
  return result;
}

export const createChildRange = (
  parentId: string,
  quantity: number
): IChildViewData[] => {
  const result = [] as IChildViewData[];
  for (var i = 0; i < quantity; i++) {
    const newId = randomViewId();
    const child: IChildViewData = {
      entity: `${newId} Entity`,
      entityId: i,
      entityType: EntityEnum.Section,
      viewId: viewId(parentId, newId),
      name: `${newId.toString()}`,
      parentId: parentId,
      sortOrder: i,
    };
    result.push(child);
  }
  return result;
};

export const entityDataToViewData = async (
  parentLevel: LevelType,
  parentId: number
) => {
  //TODO: NEED A PROPER FACTORY LOGIC TO DYNAMICALLY MANAGE THE CURRENT PARENT/CHILD VIEW HIEARARCHY
  const dataArray: IParentViewData[] = [];
  // switch (parentLevel) {
  //   case "collection":
  // const data = await fetchLocalCache<ICollectionContract>(
  //   LocalStorageEnum.activeCollection
  // );
  const data = mockCollectionEntityData;
  const qairs = _.orderBy(data.questionnaires, (x) => x.sortOrder);
  qairs.forEach((x) => {
    const children: IChildViewData[] = [];
    x.sections.forEach((y) => {
      const child: IChildViewData = {
        entity: `Entity ${y.sectionId}`, // y,
        entityId: y.sectionId,
        entityType: EntityEnum.Section,
        name: y.display,
        parentId: x.questionnaireId.toString().padStart(4, "0"),
        sortOrder: y.sortOrder,
        viewId: viewId(x.questionnaireId, y.sectionId),
      };
      children.push(child);
    });
    const parent: IParentViewData = {
      children: children,
      entityId: x.questionnaireId,
      entityType: EntityEnum.Questionnaire,
      viewId: x.questionnaireId.toString().padStart(4, "0"),
      name: x.display,
      sortOrder: x.sortOrder,
    };
    dataArray.push(parent);
  });
  // break;
  // case "questionnaire":
  //   console.log("questionnaire", parentLevel, parentId);
  //   mockCollectionEntityData.questionnaires
  //     .find((x) => x.questionnaireId == parentId)
  //     ?.sections.forEach((x) => {
  //       const children: IChildViewData[] = [];
  //       x.chapters.forEach((y) => {
  //         const child: IChildViewData = {
  //           entity: `Entity ${y.chapterId}`, // y,
  //           entityId: y.chapterId,
  //           entityType: EntityEnum.Section,
  //           name: y.display,
  //           parentId: x.sectionId.toString().padStart(4, "0"),
  //           sortOrder: y.sortOrder,
  //           viewId: viewId(x.sectionId, y.chapterId),
  //         };
  //         children.push(child);
  //       });
  //       const parent: IParentViewData = {
  //         children: children,
  //         entityId: x.sectionId,
  //         entityType: EntityEnum.Questionnaire,
  //         id: x.sectionId.toString().padStart(4, "0"),
  //         name: x.display,
  //         sortOrder: x.sortOrder,
  //       };
  //       dataArray.push(parent);
  //     });
  //   break;
  // }
  // const finalDataObject: ViewDataType = {};
  // for (let i = 0; i < dataArray.length; i++) {
  //   finalDataObject[dataArray[i].id] = dataArray[i].children;
  // }
  // return finalDataObject;
  return dataArray;
};

export const viewDataToEntityData = (
  metaData: IMetaViewData,
  viewData: IParentViewData[]
) => {
  if (viewData.length == 0) {
    return;
  }
  const parentEntityType = viewData[0].entityType;
  //TODO: Manage if parent is first or last in hierarchy
  const childEntityType = parentEntityType + 1;
  const metaEntityType = parentEntityType - 1;

  console.log("viewDataToEntityData", viewData);

  switch (metaEntityType) {
    case EntityEnum.Collection:
      const metaData = { ...mockCollectionEntityData }; //Mock data really should be an array of collections
      const workingQairs = [] as IQuestionnaireContract[];
      metaData.questionnaires.forEach((x) => {
        const found = viewData.some((y) => y.entityId === x.questionnaireId);
        found && workingQairs.push(x);
      });
      metaData.questionnaires = workingQairs;
      viewData.forEach((x) => {
        //Logic for parent and child arrays assumes sortOrders are all in place. If not, need to base position on Index in view data array.
        const workingSections = [] as ISectionContract[];
        x.children.forEach((sect) => {
          const section: ISectionContract = {
            sectionId: sect.entityId,
            display: sect.name,
            sortOrder: sect.sortOrder,
            chapters: [] as IChapterContract[],
          };
          workingSections.push(section);
        });
        const qair: IQuestionnaireContract = {
          //When posting to DB, this will be null
          questionnaireId: x.entityId,
          display: x.name,
          sortOrder: x.sortOrder,
          sections: workingSections,
        };
        const found = metaData.questionnaires.some(
          (y) => y.questionnaireId === x.entityId
        );
        if (found) {
          const index = metaData.questionnaires.findIndex(
            (z) => z.questionnaireId === x.entityId
          );
          metaData.questionnaires[index] = qair;
        } else {
          metaData.questionnaires.push(qair);
        }
      });
      console.log("metaData.questionnaires", [...metaData.questionnaires]);
      break;
    case EntityEnum.Questionnaire:
      break;
    case EntityEnum.Section:
      break;
    case EntityEnum.Chapter:
      break;
    case EntityEnum.Grouping:
      break;
  }

  console.log(
    "viewDataToEntityData",
    parentEntityType,
    childEntityType,
    metaEntityType
  );

  //   const questionnaires: IQuestionnaireContract[] = [];
  //   Object.keys(viewData).forEach((qair) => {
  //     //NEED LOGIC TO DETERMINE IF NEW OR UPDATE
  //     //WHAT WILL REALLY HAPPEN HERE IS, WILL BE UPDATING ANY ENTITIES THAT HAVE CHANGED, DELETING ANY THAT ARE REMOVED, ADDING ANY THAT ARE NEW.
  //     //WILL THAT BE DONE IN FRONT END, OR IN API?  IF IN API, THEN SEND BACK ENTIRE COLLECTION STRUCTURE AND LET API SORT IT OUT.  IF DONE HERE, THEN ONLY SEND BACK ITEMS THAT NEED TO BE DELETED OR UPSERTED
  //     const sections: ISectionContract[] = [];
  //     viewData[qair].forEach((sect) => {
  //       const section: ISectionContract = {
  //         sectionId: sect.entityId,
  //         display: sect.name,
  //         sortOrder: sect.sortOrder,
  //         chapters: [] as IChapterContract[],
  //       };
  //       sections.push(section);
  //     });
  //     const idInt = parseInt(qair);
  //     const questionnaire: IQuestionnaireContract = {
  //       questionnaireId: idInt,
  //       display:
  //         mockCollectionEntityData.questionnaires.find(
  //           (x) => x.questionnaireId == idInt
  //         )?.display ?? "",
  //       sortOrder:
  //         mockCollectionEntityData.questionnaires.find(
  //           (x) => x.questionnaireId == idInt
  //         )?.sortOrder ?? 0,
  //       sections,
  //     };
  //     questionnaires.push(questionnaire);
  //   });
  //   const collection: ICollectionContract = {
  //     collectionId: mockCollectionEntityData.collectionId,
  //     display: mockCollectionEntityData.display,
  //     sortOrder: mockCollectionEntityData.sortOrder,
  //     questionnaires,
  //   };
};

// export const viewDataToEntityData = (viewData: ViewDataType) => {
//   const questionnaires: IQuestionnaireContract[] = [];
//   Object.keys(viewData).forEach((qair) => {
//     //NEED LOGIC TO DETERMINE IF NEW OR UPDATE
//     //WHAT WILL REALLY HAPPEN HERE IS, WILL BE UPDATING ANY ENTITIES THAT HAVE CHANGED, DELETING ANY THAT ARE REMOVED, ADDING ANY THAT ARE NEW.
//     //WILL THAT BE DONE IN FRONT END, OR IN API?  IF IN API, THEN SEND BACK ENTIRE COLLECTION STRUCTURE AND LET API SORT IT OUT.  IF DONE HERE, THEN ONLY SEND BACK ITEMS THAT NEED TO BE DELETED OR UPSERTED
//     const sections: ISectionContract[] = [];
//     viewData[qair].forEach((sect) => {
//       const section: ISectionContract = {
//         sectionId: sect.entityId,
//         display: sect.name,
//         sortOrder: sect.sortOrder,
//         chapters: [] as IChapterContract[],
//       };
//       sections.push(section);
//     });
//     const idInt = parseInt(qair);
//     const questionnaire: IQuestionnaireContract = {
//       questionnaireId: idInt,
//       display:
//         mockCollectionEntityData.questionnaires.find(
//           (x) => x.questionnaireId == idInt
//         )?.display ?? "",
//       sortOrder:
//         mockCollectionEntityData.questionnaires.find(
//           (x) => x.questionnaireId == idInt
//         )?.sortOrder ?? 0,
//       sections,
//     };
//     questionnaires.push(questionnaire);
//   });
//   const collection: ICollectionContract = {
//     collectionId: mockCollectionEntityData.collectionId,
//     display: mockCollectionEntityData.display,
//     sortOrder: mockCollectionEntityData.sortOrder,
//     questionnaires,
//   };
// };
