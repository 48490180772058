import React, { useEffect, useState } from "react";

import {
  defaultDropAnimationSideEffects,
  DropAnimation,
  UniqueIdentifier,
  useDroppable,
} from "@dnd-kit/core";
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { animateLayoutChanges } from "../services/MultiHelpers";
import {
  Container,
  IContainerProps,
} from "../stories/components/Container/Container";

export const DroppableContainer = ({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  onAdd,
  onChange,
  ...props
}: IContainerProps & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: UniqueIdentifier[];
  style?: React.CSSProperties;
  onAdd?: (id: UniqueIdentifier) => void;
}) => {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: "container",
      children: items,
    },
    animateLayoutChanges,
  });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== "container") ||
      items.includes(over.id)
    : false;

  return (
    <Container
      onAdd={onAdd}
      onChange={onChange}
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
    >
      {children}
    </Container>
  );
};
